const config = {
    dev: {
        api: {
            useHTTPS: false,
            host: 'localhost',
            port: 80
        }
    },
    prod: {
        api: {
            useHTTPS: true,
            host: 'timelogger.focuszaxid.com',
            port: 443
        }
    }
};



export default process.env.NODE_ENV === 'production' ? config.prod : config.dev;